import Vue from "vue";
import Vuex from "vuex";
import i18n from "./i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: false,
    account: {},
    appConfig: {},
    // appLoading: true,
    appLoading: false,
    userAgent: {},
    expireTime: null,
    hideSidebar: false,
    minSidebar: false,
    currentProjectOBJ: {},
    localStorage : {},
    pbx_info : {},
  },

  getters: {
    getState:
      (state) =>
      (key, value = null) => {
        if (value) {
          return state[key][value];
        }
        return state[key];
      },
  },

  mutations: {
    setAuth(state, boolean) {
      state.auth = boolean;
    },
    setAccount(state, data) {
      localStorage.setItem("language", data.language);
      state.account = Object.assign({}, data);
      i18n.locale = data.language
    },
    setAppConfig(state, data) {
      state.appConfig = Object.assign({}, data);
    },
    setAppLoading(state, boolean) {
      state.appLoading = boolean;
    },
    setUserAgent(state, data) {
      state.userAgent = Object.assign({}, data);
    },
    setHideSidebar(state, hide) {
      state.hideSidebar = hide;
    },
    setMinSidebar(state, min) {
      state.minSidebar = min;
    },
    setCurrentProjectOBJ(state, data) {
      state.currentProjectOBJ = Object.assign({}, data);
    },
    setLocalStorage(state, data){
      state.localStorage = Object.assign({}, data);
    },
    setExpireTime(state, time) {
      state.expireTime = time
    },
    setPbxInfo(state, pbxInfo) {
      state.pbx_info = pbxInfo
    },
  },
});
