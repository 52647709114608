import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  theme: {
    themes: {
      light: {
        primary: "#09F",
        secondary: "#2E4A76",
        danger: "#EF3E36",
        error: "#EF3E36",
        success: "#2CBE60",
        warning: "#F5BC00",
        background: "#F7F6F3",
      },
      dark: {
        primary: "#09F",
        secondary: "#2E4A76",
        danger: "#EF3E36",
        error: "#EF3E36",
        success: "#2CBE60",
        warning: "#F5BC00",
        background: "#F7F6F3",
      },
      custom: {
        primary: "#09F",
        secondary: "#2E4A76",
        danger: "#EF3E36",
        error: "#EF3E36",
        success: "#2CBE60",
        warning: "#F5BC00",
        background: "#F7F6F3",
      },
    },
  },
  breakpoint: {
    thresholds: {
      // xs: 50,
      // sm: 476,
      // md: 668,
      // lg: 1000,
      // xl: 1300,
      mobileBreakpoint: "sm", // This is equivalent to a value of 960
    },
  },
});
