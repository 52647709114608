import i18n from "@/plugins/i18n";

function Trim(text) {
  if (text !== null && text !== undefined) {
    text = text.toString();
    return text.trim();
  }
  return;
}


function validateDomain(input) {
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  return regex.test(input);
}

function validatePort(input) {
  return input > 0 ? input < 65536 || false : false;
}

function validateIp(input) {
  const regex =
    /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/;
  return regex.test(input);
}

// let receiverNumber = this.receiver.replace(/[+]/g, ",").replace(/,/g, " ").replace(/\n/g, " ")

// function consoling(input) {
//   // (console.log("he he he he he")) 
//   return regex.test(input);
// }

const Rules = {
  required: (v) => !!v || i18n.t("REQUIRED"),

  autocomplete: (v) => !v || v.length > 0 || i18n.t("REQUIRED"),

  firstname: (v) =>
    !v ||
    (Trim(v).length > 1 && Trim(v).length < 65) ||
    i18n.t("FIELDS_MUST_AT_LEAST_MIN_number_AND_MAX_number2_CHAR", {
      number: "2",
      number2: "64",
    }),

  lastname: (v) =>
    !v ||
    (Trim(v).length > 1 && Trim(v).length < 129) ||
    i18n.t("FIELDS_MUST_AT_LEAST_MIN_number_AND_MAX_number2_CHAR", {
      number: "2",
      number2: "128",
    }),

  password: (v) =>
    !v ||
    (/^(?=.*\d).{8,32}$/.test(Trim(v)) &&
      /^(?=.*[a-zA-Z]).{8,32}$/.test(Trim(v))) ||
    i18n.t("PASSWORD_MUST_HAVE_AT_LEAST_ONE_NUMBER_OR_ONE_CHAR"),

  domain: (v) =>
    !v || validateDomain(Trim(v)) || i18n.t("INVALID_DOMAIN_FORMAT"),

  port: (v) =>
    !v || validatePort(Trim(v)) || i18n.t("INVALID_PORT_FORMAT"),

  domainOrPort: (v) => 
    !v || validateDomain(Trim(v)) || validateIp(Trim(v)) || i18n.t("INVALID_IP_OR_DOMAIN_FORMAT"),

  email: (v) =>
    !v ||
    /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(Trim(v)) ||
    // /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(Trim(v)) ||
    i18n.t("INVALID_EMAIL"),

  ip: (v) => !v || validateIp(Trim(v)) || i18n.t("INVALID_IP_FORMAT"),

  number: (v) => !v || /^\d+$/.test(Trim(v)) || i18n.t("NUMBER_REQUIRED"),

  floatNumber:(v) => !v || /^[+-]?\d+(\.\d+)?$/.test(Trim(v)) || i18n.t("NUMBER_OR_FLOAT_REQUIRED"),

  maxDigit: (v) => !v || /^(\d{1,12})(\.\d{1,12})?$/.test(Trim(v)) || i18n.t("NUMBER_UNVALID"),

  percentage: (v) => !v || /^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$/.test(Trim(v)) || i18n.t("PERCENT_REQUIRED"),

  money: (v) => !v || /^(([1-9]([0-9])?|0)*)(\.[0-9]{1,2})?$/.test(Trim(v)) || i18n.t("error"),

  lengthTwoFour: (v) =>
    !v ||
    (Trim(v).length > 1 && Trim(v).length < 5) ||
    i18n.t("LENGTH_MUST_BE_LOWER_THAN_5_AND_GREATE_THAN_2"),

  lengthOneFour: (v) =>
    !v ||
    (Trim(v).length > 0 && Trim(v).length < 5) ||
    i18n.t("LENGTH_MUST_BE_LOWER_THAN_5_AND_GREATE_THAN_2"),

  lengthFiveThirteen: (v) =>
    !v ||
    (Trim(v).length > 4 && Trim(v).length < 14) ||
    i18n.t("LENGTH_MUST_BE_LOWER_THAN_14_AND_GREATE_THAN_4"),

  phone: (v) =>
    !v ||
    (/^\d+$/.test(v) && 4 < Trim(v).length < 14) ||
    i18n.t("INVALID_PHONE_FORMAT"),

  iranPhone: (v) =>
    !v ||
    (/^\d+$/.test(v) &&
      !Trim(v).startsWith("0") &&
      5 < Trim(v).length < 11) ||
    i18n.t("INVALID_IRAN_PHONE_FORMAT"),

  iranMobile: (v) =>
    !v ||
    (/^\d+$/.test(v) &&
      Trim(v).startsWith("9") &&
      Trim(v).length == 10) ||
    i18n.t("INVALID_IRAN_MOBILE_FORMAT"),

  USAPhone: (v) =>
    !v ||
    /^\d+$/.test(v) ||
    !Trim(v).startsWith("0") ||
    !v.startsWith("1") ||
    Trim(v).length == 10 ||
    i18n.t("INVALID_USA_PHONE_FORMAT"),

  workExtension: (v) => !v || /^([0-9]{1,4})$/.test(Trim(v)) || i18n.t("WORK_EXTENSION_MUST_BETWEEN_0_AND_9999"),

  hexColor: (v) => !v || /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(Trim(v)) || i18n.t("HEX_COLOR_IS_UNVALID"),

  image: (v) => !v || v.size < 4000000 || i18n.t("IMAGE_HAS_A_VOLUME_OF_MORE_THAN_4M"),

  comfirmPassword: (v, w) => !v || v == w || i18n.t("INCOMPATIBILITY_PASSWORD_AND_COMFIRMPASSWORD"),

  maxNumber: (v, w) => !v || (+v >= 0) && (+v <= +w) || i18n.t("NUMBER_IS_GREATER_THAN_MAXIMUM"),
  minNumber: (v, w) => !v || (+v >= 0) && (+v >= +w) || i18n.t("NUMBER_IS_LOWER_THAN_MINIMUM")
};

// !isNaN(parseInt(v)) ||
// fileSize: [
//   (file) =>
//     !file ||
//     /^.*\.(jpg|jpeg|pdf|doc|docx|zip|csv|xls|xlsx|tiff|png)$/.test(
//       file.name
//     ) ||
//     this.$t("INVALID_FILE_FORMAT"),
//   (file) =>
//     !file ||
//     file.size < 1e7 ||
//     this.$t("File size should be less than 10 MB"),
// ],

export default Rules;
