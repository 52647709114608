import i18n from "@/plugins/i18n";
import store from "@/plugins/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(`@/views/Home.vue`),
    meta: {
      title: i18n.t("HOME_PAGE_TITLE"),
    },
  },
  // {
  //   path: '/forbidden',
  //   name: 'Forbidden',
  //   component: () => import('@/views/Forbidden.vue'),
  //   // component: () => import('@/views/Home.vue'),
  //   meta: {
  //     title: i18n.t("FORBIDDEN_PAGE_TITLE")
  //   },
  // },
  // {
  //   path: '/server_error',
  //   name: 'ServerError',
  //   component: () => import('../views/ServerError.vue'),
  //   meta: {
  //     requiresAuth: false,
  //     title: i18n.t("SERVER_ERROR_PAGE_TITLE")
  //   },
  // },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: i18n.t("NOT_FOUND_PAGE_TITLE")
    },
  },
];

function RouteCreator(
  routes = [],
  meta = {},
  regex = { sensitive: false, strict: true, end: false }
) {
  var result = [];
  routes.forEach((route) => {
    var capRoute = "";
    route.split("_").forEach((element) => {
      capRoute += element.charAt(0).toUpperCase() + element.slice(1);
    });
    let newMeta = structuredClone(meta);
    newMeta["title"] = i18n.t(`${route.toUpperCase()}_PAGE_TITLE`);
    result.push({
      path: `/${route}`,
      name: `${capRoute}`,
      component: () => import(`@/views/${capRoute}.vue`),
      meta: newMeta,
      pathToRegexpOptions: regex,
    });
  });
  return result;
}

const Packages = [RouteCreator(["person", "group", "delivery", "pbx", "caller_id", "media_center", "forbidden", "server_error"])];

const RouterCreator = function (router) {
  Packages.forEach((pack) => {
    routes.push.apply(routes, pack);
  });
  routes.forEach((route) => {
    router.addRoute(route);
  });

  // console.log("routes", routes)
};

export default RouterCreator;
