import Vue from "vue";
import VueRouter from "vue-router";

// handeling navigationduplicated Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

var routes = [];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

var guests = [
  "Forbidden", "Inaccessibility",
];

var noAuthRequire = [
  "NotFound", "ServerError",
];

var requiresAuth = [
  "Home", "Person", "Group", "Delivery", "MediaCenter", "Pbx",
];

router.beforeEach((to, from, next) => {
  if (to.name) {
    // console.log("to.name: ", to.name)
    document.title = to.meta.title;
    if(requiresAuth.includes(to.name)) {
      if(!window.localStorage.getItem('pbx_token')) {
        // console.log("if has not token")
        // next({ path: '/forbidden' });
        next({ name: "Forbidden" });
      } else {
        // console.log("if has token")
        next()
      }
    }
    else if(guests.includes(to.name)) {
      if(!window.localStorage.getItem('pbx_token')) {
        // console.log("else if has not token")
        next()
      } else {
        // console.log("else if has token")
        // next({ path: "/" });
        next({ name: "Home" });
      }
    }
    else {
      // console.log("else")
      next();
    }


    // console.log(sinaData)














    // if (guests.includes(to.name)) {
    //   if (window.localStorage.getItem("pbx_token")) {
    //     next({ name: "Home" });
    //   }
    // } else {
    //   if (!window.localStorage.getItem("pbx_token")) {
    //     next({ name: "Forbidden" });
    //   }
    // }
  }
  next();
})

router.afterEach((to, from) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'Telobal';
  });
})


export default router;
