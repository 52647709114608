<template>
    <!-- <meta name="viewport" content="width=device-width,initial-scale=1.0"> -->
  <dir>
    <div id="app" v-if="validDomain">
      <v-app id="app" v-if="!$store.state.appLoading">
        <v-main>
          <!-- <router-view :key="$router.name" /> -->
          <router-view />
        </v-main>

        <AppBar v-if="!notMenue.includes($route.name)" />
        <SideBar v-if="!notMenue.includes($route.name)" />
        



        <!-- <span class="footer-wrapper">
          <v-chip v-if="Boolean(exprire.color)" small class="footer mx-2" outlined :color="exprire.color"> 
            <v-icon small left :color="exprire.color" v-text="exprire.icon" :class="exprire.class" />
            <span class="text-subtitle-2 font-weight-black" v-html="exprire.text" />
          </v-chip>
        </span> -->



      </v-app>
    </div>

    <div id="app" v-if="invalidDomain">
      <NotFound v-if="notFound" noButton />
      <Forbidden v-if="forbidden" noButton />
      <ServerError v-if="serverError" noButton />
    </div>
  </dir>
</template>

<script>
import RouterCreator from "@/plugins/RouterCreator.js";
import methods from "@/core/js/methods";

export default {
  name: "App",

  components: {
    // Loading: () => import("@/core/components/Loading.vue"),
    SideBar: () => import("@/components/desktop/main/SideBar.vue"),
    AppBar: () => import("@/components/desktop/main/AppBar.vue"),
    NotFound: () => import("@/views/NotFound.vue"),
    Forbidden: () => import("@/views/Forbidden.vue"),
    ServerError: () => import("@/views/ServerError.vue"),
  },

  data: () => ({
    validDomain: false,
    invalidDomain: false,
    notFound: false,
    forbidden: false,
    serverError: false,
    homeRoute: false,
    notMenue: [
      "Forbidden",
      "Inaccessibility",
      "NotFound",
      "ServerError",
    ],

    distance: null,
    distanceTime: {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    }
  }),

  computed: {
    exprire() {
      // this.timer()
      let expireDate = new Date(this.$store.getters.getState('expireTime'))
      let t = setInterval(() => {
        let currentDate = new Date().getTime();

        this.distance = Math.floor((expireDate - currentDate) / 1000)
        // let distance = Math.floor((expireDate - currentDate) / 1000)

        if(this.distance >= 0) {

          this.distanceTime.days = Math.floor(this.distance / (60 * 60 * 24));
          this.distanceTime.hours = Math.floor((this.distance % (60 * 60 * 24)) / (60 * 60));
          this.distanceTime.minutes = Math.floor((this.distance % (60 * 60)) / (60));
          this.distanceTime.seconds = Math.floor((this.distance % (60)));
        }
        else {
          // console.log("hjshjsa", this.distance)
          clearInterval(t)
        }
      }, 1000)

      let timeData = {
        color: null,
        text: null,
        icon: null,
        class: '',
      }
      if(Boolean(this.distanceTime.days)) {
        timeData.color = 'primary'
        timeData.text = this.$t(
          'EXPRIRE_TIMER_TEXT_{days}_{hours}', 
          {
            days: this.distanceTime.days, 
            hours: this.distanceTime.hours
          }
        )
        timeData.icon = 'mdi-timer-sand'
      }
      else if(Boolean(this.distanceTime.hours)) {
        timeData.color = 'primary'
        timeData.text = this.$t(
          'EXPRIRE_TIMER_TEXT_{hours}_{minutes}', 
          {
            hours: this.distanceTime.hours, 
            minutes: this.distanceTime.minutes
          }
        )
        timeData.icon = 'mdi-timer-sand'
      }
      else if(Boolean(this.distanceTime.minutes) || Boolean(this.distanceTime.seconds)) {
        timeData.color = (this.distanceTime.minutes <= 4) ? 'error' : 'primary' 
        timeData.text = this.$t(
          'EXPRIRE_TIMER_TEXT_{minutes}_{seconds}', 
          {
            minutes: this.distanceTime.minutes,
            seconds: this.distanceTime.seconds
          }
        )
        timeData.icon = (this.distanceTime.minutes <= 4) ? Boolean(this.distanceTime.seconds % 2) ? 'mdi-timer-sand' : 'mdi-timer-sand-complete' : 'mdi-timer-sand'
      }
      else {
        timeData.color = 'error'
        timeData.text = this.$t('EXPIRE_TOKEN')
        timeData.icon = 'mdi-timer-sand-empty'
      }

      return timeData

    },
  },

  async mounted() {
    this.$store.commit("setAppLoading", true);
    var params = await this.getSearchParameters();
    
    let token = window.localStorage.getItem("pbx_token")
    if (Boolean(Object.keys(params).length)) {
      if(!params.hasOwnProperty("person")) window.localStorage.removeItem("pbx_person");
      await Object.keys(params).forEach((key) => {
        window.localStorage.setItem(`pbx_${key}`, params[key]);
      });
      this.$router.replace({name: 'Home'}).catch((err) => err)
    }

    if(Boolean(token)) {
      this.$store.commit("setLocalStorage", {
        project: window.localStorage.getItem("pbx_project"),
        person: window.localStorage.getItem("pbx_person"),
      });
    }
    // get config/site
    let url = this.$http.getUrl("configs", "site");
    this.$http.get(url, {
      params: {
        identifier: window.localStorage.getItem("pbx_identifier"),
      }
    })
    .then((response) => {
      this.validDomain = true;
      this.invalidDomain = false
      var iconURL = null;
      this.$store.commit("setUserAgent", response.data.user_agent);
      this.$store.commit("setExpireTime", response.data.expire_time);
      this.$store.commit("setPbxInfo", response.data.pbx_info);

      window.localStorage.setItem("pbx_identifier", response.data.identifier);
      if (response.data.hasOwnProperty("template")) {
        iconURL = response.data.template.images.icon;
        methods.localStorageSetter(response.data.template);
      } else {
        iconURL = this.$localStorageFinder("image_icon");
      }
      methods.vuetifySetter(this.$vuetify);
      RouterCreator(this.$router);
      // Head section
      document.title = response.data.main.brand;
      this.siteLogo = document.createElement("link");
      this.siteLogo.setAttribute("rel", "icon");
      this.siteLogo.setAttribute("href", iconURL);
      this.siteLogo.setAttribute("type", "image/icon type");
      delete response.data.template;
      this.$store.commit("setAppConfig", response.data);
      document.head.appendChild(this.siteLogo);

      this.init()
    })
    .catch((error) => {
      // console.log("server error :", error);
      this.invalidDomain = true
      this.validDomain = false
      if([403, 401].includes(error.response.status)) {
        window.localStorage.removeItem("pbx_token")
        window.localStorage.removeItem("pbx_project")
        window.localStorage.removeItem("pbx_person")
        this.forbidden = true
        this.notFound = false
        this.serverError = false
      }
      else if([500].includes(error.response.status)) {
        this.forbidden = false
        this.notFound = false
        this.serverError = true
      }
      else {
        this.forbidden = false
        this.notFound = true
        this.serverError = false
      }
        
    })
    .finally((_) => {
      this.$store.commit("setAppLoading", false);
    })
    
  },

  methods: {
    init() {
      let token = window.localStorage.getItem("pbx_token")
      if(Boolean(token)) {
        let url = this.$http.getUrl("configs", "info");
        this.$http.get(url).then((response) => {
          this.$store.commit("setCurrentProjectOBJ", response.data.project);
          this.$store.commit("setAccount", response.data.client);
          this.$store.commit("setAuth", true);
        })
        .catch((error) => {
          // console.log("server error :", error);
          // if (process.env.NODE_ENV === "development") {
          //   console.log("domain not found");
          // } else {
          //   window.location.href = "/500";
          // }
        })
        .finally((_) => {
          this.$store.commit("setAppLoading", false);
        })
      }
      else {
        this.$router.push({ path: '/forbidden' })
      }
    },

    getSearchParameters() {
      console.log(window.location.search);
      var prmstr = window.location.search.substr(1);
      // console.log("prmstr", prmstr, Boolean(prmstr))
      // return prmstr != null && prmstr != ""
      return Boolean(prmstr) ? this.transformToAssocArray(prmstr) : ''
      // if(Boolean(prmstr)) return this.transformToAssocArray(prmstr)

    },

    transformToAssocArray(prmstr) {
      var params = {};
      var prmarr = prmstr.split("&");
      prmarr.forEach(str => {
        var tmparr = str.split("=")
        params[tmparr[0]] = tmparr[1]
      })
      return params;
    },

  },
};
</script>

<style>
.footer-wrapper {
  position: fixed;
  width: auto;
  bottom: 20px;
  right: 50px;
  z-index: 10;
}

.footer {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 5px;
  margin: 0 5px;
}
</style>
