import Axios from "axios";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

const origin = process.env.VUE_APP_BACKEND_API
  ? process.env.VUE_APP_BACKEND_API
  : document.location.origin;

const apiPath = process.env.VUE_APP_BACKEND_API_PATH
  ? process.env.VUE_APP_BACKEND_API_PATH
  : "/api/v1/";
  
const baseUrl = origin + apiPath;

const Request = Axios.create({
  timeout: 10000,
  baseURL: baseUrl,
});

Request.urls = {// GET, POST, PATCH, DELETE
  configs: { // 0.3 Day
    site: "/site/", // GET
    info: "/info/", // GET
    logout: "/logout/", // POST
    renew: "/renew/", // POST
    // caller_id: "/caller_id/",
  },
  persons: { // !cleaning
    root: "/",
    update_delete: "/pk/",
    location: "/pk/location/",
    internal_number: "/internal_number/",
    subscribers: "/pk/subscribers/",
    reset_password: "/pk/subscribers/reset_password/",
    extra_phone: "/pk/extra_phone/",
    phones: "/pk/phones/"

  },
  group: { // 0.3 Day
    root: "/", // GET, POST
    update_delete: "/pk/", // DELETE
    member: "/pk/member/", // POST
  },
  delivery: { // 1 Day
    root: "/", // GET
    email: "/email/", // POST
    email_primary: "/email/pk/handler/", // GET
    email_update_delete: "/email/pk/", // PATCH, DELETE
  },
  media: { // 1.5 Day
    personal_root: "/personal/", // GET, POST
    update_delete: "/personal/pk/", // PATCH, DELETE
    personal_files: "/personal/pk/file/", // GET, POST, PATCH, DELETE
    public_root: "/public/", // GET
    public_search: "/public/search/", // POST
  },
  pbx: { // 0.5 Day
    root : "/modules/", // GET, POST
    update_delete: "/modules/pk/", // PATCH, DELETE
    location : "/modules/pk/location/", // POST
    link_root : "/link/", // POST
    link_update_delete: "/link/pk/", // PATCH, DELETE
  },
  caller_id: { // 0.5 Day
    root: "/", // GET, POST, PATCH
    delete_id: '/pk/', // DELETE
    rate: "/rate/", // POST
    verify: "/verify/" // POST
  },
};

Request.formatUrl = function (url, pk) {
  return url.replace(/pk/g, pk);
};

Request.getUrl = function (name, path) {
  return name + this.urls[name][path];
};

Request.interceptors.request.use(
  (config) => {
    let token = window.localStorage.getItem("pbx_token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    config.headers["X-Project"] = localStorage.getItem("pbx_project");
    if (localStorage.getItem("pbx_person")) {
      config.headers["X-Person"] = localStorage.getItem("pbx_person");
    }
    config.headers["X-Redirect"] = localStorage.getItem("pbx_redirect");
    config.onUploadProgress = function (progressEvent) {
      let uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    };

    // config.onUploadProgress = function (progressEvent) {
    //   let uploadPercentage = parseInt(
    //     Math.round((progressEvent.loaded / progressEvent.total) * 100)
    //   );

    //   if (uploadPercentage === 100) store.commit("setOverlay", { percent: 0 });
    //   else store.commit("setOverlay", { percent: uploadPercentage });
    // };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }

  // (config) => {
  //   config.headers["Authorization"] = `Token ${localStorage.getItem("pbx_token")}`;
  //   // config.headers["X-Project"] = localStorage.getItem("pbx_project");
  //   // if (localStorage.getItem("pbx_person")) {
  //   //   config.headers["X-Person"] = localStorage.getItem("pbx_person");
  //   // }
  //   config.onUploadProgress = function (progressEvent) {
  //     let uploadPercentage = parseInt(
  //       Math.round((progressEvent.loaded / progressEvent.total) * 100)
  //     );
  //   };
  //   return config;
  // },
  // (error) => {
  //   return Promise.reject(error);
  // }
);

Request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status == 401) {
        // console.log("401", error.response)
        
        window.localStorage.removeItem("pbx_token");
        window.localStorage.removeItem("pbx_project");
        window.localStorage.removeItem("pbx_person");
        // window.localStorage.removeItem("pbx_redirect");
        // location.href = "/";
        // window.location.href = "/500";
        // window.location.href = "/inaccessibility";
        // 


        
        let redirect = window.localStorage.getItem('pbx_redirect')
        // if(Boolean(redirect)) window.location.href = `http://${decodeURIComponent(redirect)}`
        if(Boolean(redirect)) window.location.href = `http://${redirect}`
        else router.push({ path: '/forbidden' })

      }
      if (error.response.status == 403) {
        // not found error
        // console.log("error.response.status=403 from cu_axios.js: ");
        window.localStorage.removeItem("pbx_token");
        window.localStorage.removeItem("pbx_project");
        window.localStorage.removeItem("pbx_person");
        // window.localStorage.removeItem("pbx_redirect");
        // location.href = "/";
        // window.location.href = "/500";
        // window.location.href = "/inaccessibility";
        router.push({ path: '/forbidden' })
      }
      if (error.response.status == 404) {
        // not found error
        if(process.env.NODE_ENV != 'development') {
          let redirect = window.localStorage.getItem('pbx_redirect')
          if(Boolean(redirect)) window.location.href = `${new URL(window.location.origin).protocol}//${(redirect)}`
          else router.push({ name: 'NotFound' })
        }
      }
      if (error.response.status == 500) {
        // server error
        if (process.env.NODE_ENV == 'development') {
          // console.log(
          //   "error.response.status=500 from cu_axios.js: ",
          //   error.response
          // );
        } else {
          router.push({ name: 'ServerError' })
        }
        // router.push({ name: 'ServerError' })

      }
      if (error.response.status == 503) {
        window.location.reload()
      }
    }
    return Promise.reject(error);
  }
);

export default Request;
